export const environment = {
    production: false,
    api: {
        urlMerchant: 'https://stg-api-cms-merchant.wepay4u.com/api/v1/',
        urlClientMerchant: 'https://stg-api-client-merchant.wepay4u.com/v1/',
        urlAdmin: 'https://stg-api-cms-admin.wepay4u.com/api/v1/',
        urlAccess: 'https://stg-api-core-access.wepay4u.com/v1/',
    },
};

